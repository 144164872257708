import React from "react"
import { PortableText } from "@portabletext/react"

const SPABox = ({ data, title, spaDescription }) => {
  return (
    <>
      <div
        className="spa_blk"
        data-aos="fade-up"
        data-aos-delay={`${50 + 0 * 50}`}
        data-aos-duration={`${1000 + 0 * 100}`}
      >
        <>
          <h3>{title}</h3>
          <div className="spa_blk_description">
            <PortableText value={spaDescription} />
          </div>
        </>
      </div>
      {data.map((item, index) => (
        <div
          className="spa_blk"
          data-aos="fade-up"
          data-aos-delay={`${50 + (index + 1) * 50}`}
          data-aos-duration={`${1000 + (index + 1) * 100}`}
        >
          <h4>{item.title}</h4>
          <PortableText value={item._rawDescription} />
        </div>
      ))}
    </>
  )
}
export default SPABox
