import React, { useEffect } from "react"
import Layout from "../../components/Layout"
import SPABox from "../../components/SPABox"
import BookingOnline from "../../components/BookingOnline"
import Aos from "aos"
import BackToTop from "../../components/BackToTop"
import { graphql } from "gatsby"
import Seo from "../../components/Seo"
import { GatsbyImage } from "gatsby-plugin-image"

const SPA = ({ data }) => {
  useEffect(() => {
    Aos.init()
  }, [])
  const { title, _rawDescription, heroImg, spaTypes, logo, images } =
    data?.sanityPages?.spaPage
  return (
    <>
      <Seo
        title={"SPA Page"}
        description={"Noyo Harbor offering spa rooms for customer's."}
      />
      <Layout>
        <div className="header_bottom header_3">
          <div className="container">
            <BookingOnline />
          </div>
          <BackToTop />
        </div>
        <div className="spa__section">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div className="spa_all_content">
                  <SPABox
                    data={spaTypes}
                    title={title}
                    spaDescription={_rawDescription}
                  />
                </div>
              </div>
              <div
                className="col-lg-5"
                data-aos-delay="200"
                data-aos-duration="1000"
              >
                <div className="history_thumb spa">
                  <GatsbyImage
                    image={heroImg?.asset.gatsbyImageData}
                    alt="SPA Image"
                    objectFit="cover"
                    objectPosition="center"
                    loading="eager"
                  />
                </div>

                <div className="mt-4">
                  <GatsbyImage
                    image={logo?.asset.gatsbyImageData}
                    alt="Spa Logo"
                    objectFit="cover"
                    objectPosition="center"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="rooms__area special_sect">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="room_blk">
                  <div className="row">
                    {images.map((item, index) => (
                      <div className="col-lg-6 col-md-6 col-sm-6" key={index}>
                        <div
                          className="rooms_wrap"
                          data-aos="fade-up"
                          data-aos-delay={`${100 + index * 50}`}
                          data-aos-duration={`${1000 + index * 100}`}
                        >
                          <div className="rooms_thumb spa">
                            <GatsbyImage
                              image={item?.asset.gatsbyImageData}
                              alt="SPA Gallery Image"
                              objectFit="cover"
                              objectPosition="center"
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}
export default SPA
export const spaData = graphql`
  query ($id: String!) {
    sanityPages(id: { eq: $id }) {
      title
      template
      handle {
        current
      }
      spaPage {
        title
        _rawDescription
        heroImg {
          asset {
            gatsbyImageData(fit: FILLMAX, formats: WEBP)
          }
        }
        spaTypes {
          title
          _rawDescription
        }
        logo {
          asset {
            gatsbyImageData(fit: FILLMAX, formats: WEBP)
          }
        }
        images {
          asset {
            gatsbyImageData(fit: FILLMAX, formats: WEBP)
          }
        }
      }
    }
  }
`
